/*
 * File: PartnerSelection.tsx
 * Project: autobuy-back-office
 * File Created: Tuesday, 22nd August 2023 09:57:32
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 15th September 2023 03:34:51
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { useEffect, useState, Fragment } from "react";
import SwitchsGroups from "Content/Switchs/SwitchsGroups";
import {Form, Col} from "react-bootstrap";
import translator from "MultiLanguage/TranslationWrapper";
import DragGalery from "Content/DragGalery/DragGalery";
import { ShowingPicture } from "Type/picture.type";
import Styles from "./PartnerSelection.module.css";
import { PartnerType } from "Type/partner.type";
import PartnerService from "Services/PartnerService/PartnerService";
import { SwitchType } from "Type/SwitchGroups.type";

export type PartnerSelectionProps = {
    partners?: PartnerType[],
    selectionCallback: Function,
    pictureSelection: Function
}

const PartnerSelectionComponent = (props: PartnerSelectionProps) => {
    const [partners, setPartners] = useState<SwitchType[]>([]);
    const [pictureBoxes, setPictureBoxes] = useState<ShowingPicture[]>([]);

    useEffect(() => {
        PartnerService.getAllPartners()
        .then( (items: PartnerType[]) => {
            setPartners(items.map((item => props.partners?.some(val => val.id === item.id) ? {item, checked: true}: {item, checked: false})));
        }).catch((e)=> {
            console.error(e);
        });
    }, [props.partners]);

    useEffect(() => {
        let partnersBoxes: ShowingPicture[] = [];
        for (let i = 0; i < partners.length; ++i)
            if (partners[i].checked)
                partnersBoxes.push({ id: partners[i].item.Pic?.id ?? `pic-${i}`, Name: partners[i].item.Name, Url: partners[i].item.Pic?.URI, order: i});
        setPictureBoxes(partnersBoxes);
    }, [partners]);

    useEffect(() => {
        props.pictureSelection(pictureBoxes);
    }, [pictureBoxes]);

    const updateSelectedPartners = (data: SwitchType[]) => {
        setPartners(data);
        props.selectionCallback(data.filter((item) => item.checked == true).map((item) => item.item));
    };

    return (
        <Fragment>
            <SwitchsGroups title={"dynamic.partners"} data={partners} callback={updateSelectedPartners} />
            {
                pictureBoxes.length !== 0 ?
                    <Form.Group className={`${Styles.formfield}`} >
                        <Form.Label>
                            {translator.decode("partner.partnerPic")}:
                        </Form.Label>
                        <Col className={`${Styles.formfield}`}>
                            <DragGalery items={pictureBoxes} func={setPictureBoxes} />
                        </Col>
                    </Form.Group>
                    :null
            }
        </Fragment>
    )

}

export default PartnerSelectionComponent;
