/*
 * File: GroupList.tsx
 * Project: autobuy-back-office
 * File Created: Tuesday, 22nd August 2023 02:06:09
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 15th September 2023 03:46:35
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import SwitchsGroups from "Content/Switchs/SwitchsGroups";
import GroupService from "Services/GroupService/GroupService";
import { SwitchType } from "Type/SwitchGroups.type";
import { GroupType } from "Type/group.type";
import { useEffect, useState } from "react";

type GroupListProps = {
    title: string,
    callback: Function,
    groups?: GroupType[],
    oppositeGroups?: GroupType[],
    customClass?: string,
}

const GroupList = (props: GroupListProps) => {
    const [groups, setGroups] = useState<SwitchType[]>([]);

    useEffect(() => {
        GroupService.getAllGroups()
        .then( (items: GroupType[]) => {
            setGroups(items.map(item =>
                props.groups?.some(val => val.id === item.id) ? {item, checked: true}: {item, checked: false}));
            // setGroups(items);
        })
        .catch((reason) => {
            console.error(reason);
        });
    }, [props.groups]);

    useEffect(() => {
        for (let group of groups)
            if (props.oppositeGroups?.some(val => val.id === group.item.id))
                group.checked = false;
        setGroups([...groups]);
    }, [props.oppositeGroups]);

    const updateSelectedGroups = (data: SwitchType[]) => {
        setGroups(data);
        props.callback(data.filter((item) => item.checked == true).map((item) => item.item));
    };

    return (
        <SwitchsGroups title={props.title} data={groups} callback={updateSelectedGroups} customClass={props.customClass} />
    )
}

export default GroupList;
